import {
    FETCH_TRANSACTION_DATA_BEGIN,
    FETCH_TRANSACTION_DATA_FAILURE,
    FETCH_TRANSACTION_DATA_SUCCESS
} from "../../types";
import {GetTransactions} from "../../../api/queries";

export function fetchTransactionData({pair, token, limit, page, starting, sortBy,blacklist,table}) {
    return (dispatch) => {
        dispatch({
            type: FETCH_TRANSACTION_DATA_BEGIN
        });
        const offset = page
        return new Promise((resolve, reject) => {
            GetTransactions(pair, token, limit, offset, starting, sortBy,blacklist)
                .then((result)=> {
                    
                    dispatch({
                        type: FETCH_TRANSACTION_DATA_SUCCESS,
                        data: result,
                        path: table
                    })
                    resolve(result);
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_TRANSACTION_DATA_FAILURE
                    });
                    console.log(error);
                    return reject(error.message || error);
                })
            })
    }
}
