import {
    FETCH_SIGNAL_COUNT_SUCCESS,
    FETCH_SIGNAL_COUNT_FAILURE,
    FETCH_SIGNAL_COUNT_BEGIN
} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case FETCH_SIGNAL_COUNT_BEGIN:
            return {
                ...state,
                fetchSignalCountDataPending: true,
            };

        case FETCH_SIGNAL_COUNT_FAILURE:
            return {
                ...state,
                fetchSignalCountDataPending: false,
            };

        case FETCH_SIGNAL_COUNT_SUCCESS:
            return {
                ...state,
                countSignalData:action.data,
                fetchSignalCountDataPending: false,
            };

        default:
            return state;
    }
};
