import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchToken} from '../../actions/vault/fetchToken';

export function useFetchToken() {
    const dispatch = useDispatch();

    const {fetchTokenPending,tokenData} = useSelector(
        (state) => ({
            fetchTokenPending: state.vault.fetchTokenPending,
            tokenData: state.vault.tokenData
        }),
        shallowEqual,
    );

    const boundAction = useCallback(
        (data) => {
            return dispatch(fetchToken(data));
        },
        [dispatch],
    );

    return {
        fetchToken: boundAction,
        fetchTokenPending,tokenData
    };
}
