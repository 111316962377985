import {
    FETCH_FAVORITES_BEGIN,
    FETCH_FAVORITES_FAILURE,
    FETCH_FAVORITES_SUCCESS
} from "../../types";
import {GetFavorites} from "../../../api/queries";

export function fetchStarred({address}) {
    return (dispatch) => {
        dispatch({
            type: FETCH_FAVORITES_BEGIN
        });
        return new Promise((resolve, reject) => {
            GetFavorites(address)
                .then((result)=> {
                    
                    dispatch({
                        type: FETCH_FAVORITES_SUCCESS,
                        data: result
                    })
                    resolve(result);
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_FAVORITES_FAILURE
                    });
                    console.log(error);
                    return reject(error.message || error);
                })
        })
    }
}
