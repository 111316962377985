export const Summary_Table = "Summary_Table";
export const Signals_Table = "Signals_Table";
export const News_Table = "News_Table";
export const Seller_Table = "Seller_Dashboard_Table";
export const Buyer_Table = "Buyer_Dashboard_Table";

export const Transaction_Table = "Transaction_Table";
export const Transaction_Table_Bought = "Transaction_Table_Bought";
export const Transaction_Table_Sold = "Transaction_Table_Sold";

export const FETCH_BALANCES_BEGIN = "FETCH_BALANCES_BEGIN";
export const FETCH_BALANCES_SUCCESS = "FETCH_BALANCES_SUCCESS";
export const FETCH_BALANCES_FAILURE = "FETCH_BALANCES_FAILURE";

export const FETCH_CHART_DATA_BEGIN = "FETCH_CHART_DATA_BEGIN";
export const FETCH_CHART_DATA_SUCCESS = "FETCH_CHART_DATA_SUCCESS";
export const FETCH_CHART_DATA_FAILURE = "FETCH_CHART_DATA_FAILURE";

export const FETCH_TRANSACTION_DATA_BEGIN = "FETCH_TRANSACTION_DATA_BEGIN";
export const FETCH_TRANSACTION_DATA_SUCCESS = "FETCH_TRANSACTION_DATA_SUCCESS";
export const FETCH_TRANSACTION_DATA_FAILURE = "FETCH_TRANSACTION_DATA_FAILURE";

export const FETCH_SIGNAL_DATA_BEGIN = "FETCH_SIGNAL_DATA_BEGIN";
export const FETCH_SIGNAL_DATA_SUCCESS = "FETCH_SIGNAL_DATA_SUCCESS";
export const FETCH_SIGNAL_DATA_FAILURE = "FETCH_SIGNAL_DATA_FAILURE";

export const FETCH_TRANSACTION_COUNT_BEGIN = "FETCH_TRANSACTION_COUNT_BEGIN";
export const FETCH_TRANSACTION_COUNT_SUCCESS = "FETCH_TRANSACTION_COUNT_SUCCESS";
export const FETCH_TRANSACTION_COUNT_FAILURE = "FETCH_TRANSACTION_COUNT_FAILURE";

export const FETCH_SIGNAL_COUNT_BEGIN = "FETCH_SIGNAL_COUNT_BEGIN";
export const FETCH_SIGNAL_COUNT_SUCCESS = "FETCH_SIGNAL_COUNT_SUCCESS";
export const FETCH_SIGNAL_COUNT_FAILURE = "FETCH_SIGNAL_COUNT_FAILURE";

export const FETCH_FAVORITES_BEGIN = "FETCH_FAVORITES_BEGIN";
export const FETCH_FAVORITES_SUCCESS = "FETCH_FAVORITES_SUCCESS";
export const FETCH_FAVORITES_FAILURE = "FETCH_FAVORITES_FAILURE";
