import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchSignalData} from "../../actions/vault/fetchSignalData";
import {fetchSignalCount} from "../../actions/vault/fetchSignalCount";

export function useFetchSignalData() {
    const dispatch = useDispatch();

    const {signalData, fetchSignalDataPending,countSignalData,fetchSignalCountDataPending} = useSelector(
        (state) => ({
            signalData: state.vault.signalData,
            fetchSignalDataPending: state.vault.fetchSignalDataPending,
            countSignalData: state.vault.countSignalData,
            fetchSignalCountDataPending: state.vault.fetchSignalCountDataPending
        }),
        shallowEqual,
    );

    const boundAction1 = useCallback(
        (data) => {
            return dispatch(fetchSignalData(data));
        },
        [dispatch],
    );

    const boundAction2 = useCallback(
        (data) => {
            return dispatch(fetchSignalCount(data));
        },
        [dispatch],
    );


    return {
        signalData,
        fetchSignalData: boundAction1,
        fetchSignalCountData: boundAction2,
        fetchSignalDataPending,
        countSignalData,
        fetchSignalCountDataPending
    };
}
