import {FETCH_FAVORITES_FAILURE, FETCH_FAVORITES_SUCCESS, FETCH_FAVORITES_BEGIN} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case FETCH_FAVORITES_BEGIN:
            return {
                ...state,
                fetchStarredPending: true,
            };

        case FETCH_FAVORITES_FAILURE:
            return {
                ...state,
                fetchStarredPending: false,
            };

        case FETCH_FAVORITES_SUCCESS:
            return {
                ...state,
                starred: action.data,
                addressFavList: action.data.map((e)=>{
                    return e.favorited_address
                }),
                fetchStarredPending: false,
            };

        default:
            return state;
    }
};
