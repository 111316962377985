import {combineReducers} from 'redux';
import walletReducer from './wallet/reducer';
import vaultReducer from './vault/reducer';
import infoReducer from './info/reducer';
import globalSettingsReducer from './globalSettings/reducer'

export default combineReducers({
    wallet: walletReducer,
    vault: vaultReducer,
    info: infoReducer,
    globalSettings: globalSettingsReducer
});
