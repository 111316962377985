import React,{useEffect,useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import {GetACSinfo, GetACSIinfo, GetBNBinfo, GetPancakeinfo, GetTVL,GetVenusTVL} from "../../api/queries"
import GridContainer from "../Grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import {convertToInternationalCurrencySystem} from '../../utils/convertToCurrency'
import {useFetchToken} from "../../store/hooks/vault/useFetchToken";
import {useConnectWallet} from "../../store/hooks/wallet";
import {acsAcsToken} from "../../contracts/tokens/acsAcsToken";
import {acsAcsiToken} from "../../contracts/tokens/acsAsciToken";
import BigNumber from "bignumber.js";
import {createMuiTheme,ThemeProvider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles(styles);

const useCustomStyle = makeStyles(() => ({
  centerText: {
    width:"100%",
    textAlign:"center"
  },

}));

const theme = createMuiTheme();

theme.typography.subtitle2 = {
  color:"grey",
  fontSize: '0.875rem',
};

export default function Header(props) {
  const classes = useStyles();
  const classesCustom = useCustomStyle();
  const [acs,setAcs]=useState([]);
  const [acsi,setAcsi]=useState([]);
  const [bnb,setBnb]=useState([]);
  const [cake,setCake]=useState();
  const [tvl,setTVL] = useState(0)
  const [venusTVL,setVenusTVL] = useState(0)

  const [acsACSPPFS,setAcsACSPPFS] = useState(0);
  const [acsACSIPPFS,setAcsACSIPPFS] = useState(0);
  const {web3} =  useConnectWallet()
  // const {fetchACSinfo,fetchACSinfoPending,acsInfo} = useFetchACSinfo()

  useEffect(() => {
    GetACSinfo().then(res=>{setAcs([res.market_data.current_price.usd,convertToInternationalCurrencySystem(res.market_data.market_cap.usd)])})

    GetBNBinfo().then(res=>{setBnb([res.market_data.current_price.usd,convertToInternationalCurrencySystem(res.market_data.market_cap.usd)])})

    GetACSIinfo().then(res=>{setAcsi([res.market_data.current_price.usd,convertToInternationalCurrencySystem(res.market_data.market_cap.usd)])})

    GetPancakeinfo().then(res=>{setCake(convertToInternationalCurrencySystem(res.total_value_locked_all))})

    GetTVL().then(res=>{setTVL(res.acs)})

    GetVenusTVL().then(res=>{setVenusTVL(res.venus.split(" ")[0])})



  },[])

  useEffect(
      ()=> {
        if(web3!==null) {
          fetchToken({web3, tokens: [acsAcsToken, acsAcsiToken]})
        }
      }
  ,[web3])


  const {tokenData,fetchToken} =  useFetchToken()
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  // console.log("ya ayree,",acsInfo)

  useEffect(()=>{
    if(tokenData.acsACS && tokenData.acsACSI) {
      setAcsACSIPPFS(new BigNumber(tokenData.acsACSI.pricePerFullShare).toFormat(2))
      setAcsACSPPFS(new BigNumber(tokenData.acsACS.pricePerFullShare).toFormat(2))
    }
  },[tokenData])
  return (
    <AppBar className={classes.appBar + appBarClasses}>

      <Toolbar className={classes.container}>
          {/* Here we create navbar brand, based on route name */}
            <div className={classes.flex}>
        <GridContainer spacing={3} margin={3}>

          {/*<Grid item xs={2} md={2} lg={2}>*/}
          {/*<Button color="transparent" href="#" className={classes.title}>*/}
          {/*  {makeBrand()}*/}
          {/*</Button>*/}
          {/*</Grid>*/}
            <Grid className={classesCustom.centerText} item xs={2} md lg >
              <ThemeProvider theme={theme}>
                <Typography variant="subtitle2">&nbsp;&nbsp;ACS: {acs[0]}/ {acs[1]}</Typography>
              </ThemeProvider>
            </Grid>
            <Grid className={classesCustom.centerText} item xs={2} md lg>
              <ThemeProvider theme={theme}>
                <Typography variant="subtitle2">&nbsp;&nbsp;ACSI: {acsi[0]}/ {acsi[1]}</Typography>
              </ThemeProvider>
            </Grid>
            <Grid className={classesCustom.centerText} item xs={2}  md lg>
              <ThemeProvider theme={theme}>
                <Typography variant="subtitle2">&nbsp;&nbsp;BNB: {bnb[0]}/ {bnb[1]}</Typography>
              </ThemeProvider>
            </Grid>
            <Grid className={classesCustom.centerText} item xs={2}  md lg>
              <ThemeProvider theme={theme}>
                <Typography variant="subtitle2">&nbsp;&nbsp;ACS TVL: {tvl}M</Typography>
                <Typography variant="subtitle2">&nbsp;&nbsp;Venus TVL: {venusTVL}</Typography>
                <Typography variant="subtitle2">&nbsp;&nbsp;Pancake TVL: {cake}</Typography>

              </ThemeProvider>
            </Grid>
            <Grid className={classesCustom.centerText} item xs={2}  md lg>
              <ThemeProvider theme={theme}>
                <Typography variant="subtitle2">&nbsp;&nbsp;acsACS: {acsACSPPFS}</Typography>
                <Typography variant="subtitle2">&nbsp;&nbsp;acsACSI: {acsACSIPPFS}</Typography>
              </ThemeProvider>

            </Grid>
          </GridContainer>

            </div>

        <Hidden smDown implementation="css">
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
