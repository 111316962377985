import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import tokens from "../../contracts/tokens"
import {useFetchBalances} from "../../store/hooks/vault/useFetchBalances";
import {News_Table} from "../../store/types";
import {useConnectWallet} from "../../store/hooks/wallet";
import {TableCell, TableContainer, TableFooter, TablePagination, TextField} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {NewRow} from "../../components/Rows/NewRow";
import{GetNewBuyers,GetNewSellers,GetNewBuyersCount,GetNewSellersCount} from "../../api/queries"
import {useChangeToken} from "../../store/hooks/globalSettings/useChangeToken";

const useStyle = makeStyles((theme) => ({
        cardCategoryWhite: {
            "&,& a,& a:hover,& a:focus": {
                color: "rgba(255,255,255,.62)",
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0"
            },
            "& a,& a:hover,& a:focus": {
                color: "#FFFFFF"
            }
        },
        cardTitleWhite: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: "300",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    }
))



export default function New() {
    const classes = useStyle();

    const {web3} = useConnectWallet();
    const {allBalances, fetchBalances} = useFetchBalances();
    const {globalTokenAddress,globalPair}=useChangeToken()
    const balances = allBalances[News_Table];
    const [presetStarting, setPresetStarting] = useState(86400)
    const [customStarting, setCustomStarting] = useState(new Date())
    const [starting, setStarting] = useState(Math.round(new Date().getTime()/1000) - presetStarting)
    const [customFlag, setCustomFlag] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [addresses, setAddresses] = useState([])
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [limit, setLimit] = useState(10)
    const [tableData,setTableData]  = useState([]);
    const [operation,setOperation]=useState(1)

    useEffect(() => {
        if(web3!==null) {
            fetchBalances({
                addresses, web3, table: News_Table
            })
        }
    }, [addresses,web3])

    // useEffect(() => {
    //     GetNewBuyers({pair, token, starting,limit,page}).then(
    //         (e) => {
    //             setAddresses(e.map((a) => {
    //                 return a.address
    //             }))
    //         }
    //     )

    // },[pair, token, starting,limit,page]);
    useEffect(() => {
        if(operation){
        GetNewSellers(globalPair, globalTokenAddress, starting,limit,page).then(
            (e) => {
                setTableData(e)
                setAddresses(e.map((a) => {
                    return a.address
                }))

            }
        )
        GetNewSellersCount(globalPair, globalTokenAddress, starting).then(e=>{
            setCount(e[0].count)
        })}
        else{
            GetNewBuyers(globalPair, globalTokenAddress, starting,limit,page).then(
                (e) => {
                    setTableData(e)
                    setAddresses(e.map((a) => {
                        return a.address
                    }))

                }
            )
            GetNewBuyersCount(globalPair, globalTokenAddress, starting).then(e=>{
                setCount(e[0].count)
            })
        }

    },[globalPair, globalTokenAddress, starting,limit,page,operation]);

    const presetStartingChange = (event) => {
        setPresetStarting(event.target.value);
        if(event.target.value=== -1){
            setCustomFlag(true)
        }
        else if(event.target.value=== 0){
            setCustomFlag(false)
            setStarting(event.target.value);
        }
        else {
            setCustomFlag(false)
            setStarting(Math.round(new Date().getTime()/1000) - event.target.value);
        }
    };

    const customStartingChange = (event) => {
        setCustomStarting(event);
        if(customStarting)
            setStarting(Math.round(event.getTime()/1000))
        else
            setStarting(0)
    };

    const operationChange = () => {
        if(operation==1)setOperation(0)
        else{
            setOperation(1)
        }

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setLimit(event.target.value)
        setPage(0);

    };

    const tokenIds = tokens.map((token) => {
        return `${token[0].id}(${token[1].id})`
    });

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>New {operation? 'Sellers': 'Buyers'}</h4>
                    </CardHeader>
                    <CardBody>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="operation">Operation</InputLabel>
                            <Select
                                value={operation}
                                onChange={operationChange}
                            >
                                <MenuItem value={0}>Buy</MenuItem>
                                <MenuItem value={1}>Sell</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="input-time-scale-label">Starting</InputLabel>
                            <Select
                                labelId="select-time-scale"
                                id="time-scale"
                                value={presetStarting}
                                onChange={presetStartingChange}
                            >
                                <MenuItem value={-1}>Custom</MenuItem>
                                <MenuItem value={0}>All Time</MenuItem>
                                <MenuItem value={3600}>Last Hour</MenuItem>
                                <MenuItem value={14400}>Last 4 Hours</MenuItem>
                                <MenuItem value={43200}>Last 12 Hours</MenuItem>
                                <MenuItem value={86400}>Last Day</MenuItem>
                                <MenuItem value={259200}>Last 3 Days</MenuItem>
                                <MenuItem value={604800}>Last Week</MenuItem>
                                <MenuItem value={1209600}>Last 2 Weeks</MenuItem>
                            </Select>
                        </FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                clearable
                                value={customStarting}
                                onChange={customStartingChange}
                                style={{
                                    borderRadius: 5,
                                    paddingTop: 10,
                                    background: "white",
                                    display: customFlag ? "inline-flex" : "none"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <TableContainer>
                            <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Tx count</TableCell>
                                        <TableCell>First Operation date</TableCell>
                                        {tokenIds.map((token) => (
                                            <TableCell>{token}</TableCell>
                                        ))}
                                        <TableCell>Description</TableCell>
                                        <TableCell>Starred</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {  Object.keys(balances).length !== 0 &&
                                    tableData.map((row) => (
                                         <NewRow key={row.address} row={row} balances={balances[row.address]}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10]}
                                    colSpan={3}
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                />
                            </TableRow>
                        </TableFooter>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

