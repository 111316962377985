import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchTransactionData} from "../../actions/vault/fetchTransactionData";
import {fetchTransactionCount} from "../../actions/vault/fetchTransactionCount";

export function useFetchTransactionData() {
    const dispatch = useDispatch();

    const {fetchTransactionDataPending,fetchTransactionCountDataPending,Transaction_Table_Data
    ,Transaction_Table_Count,Transaction_Table_Bought_Data,Transaction_Table_Bought_Count,Transaction_Table_Sold_Data
    ,Transaction_Table_Sold_Count} = useSelector(
        (state) => ({
            Transaction_Table_Data: state.vault.Transaction_Table_Data,
            Transaction_Table_Count: state.vault.Transaction_Table_Count,
            Transaction_Table_Bought_Data: state.vault.Transaction_Table_Bought_Data,
            Transaction_Table_Bought_Count: state.vault.Transaction_Table_Bought_Count,
            Transaction_Table_Sold_Data: state.vault.Transaction_Table_Sold_Data,
            Transaction_Table_Sold_Count: state.vault.Transaction_Table_Sold_Count,
            fetchTransactionDataPending: state.vault.fetchTransactionDataPending,
            fetchTransactionCountDataPending: state.vault.fetchTransactionCountDataPending
        }),
        shallowEqual,
    );

    const boundAction1 = useCallback(
        (data) => {
            return dispatch(fetchTransactionData(data));
        },
        [dispatch],
    );

    const boundAction2 = useCallback(
        (data) => {
            return dispatch(fetchTransactionCount(data));
        },
        [dispatch],
    );


    return {
        fetchTransactionData: boundAction1,
        fetchTransactionCountData: boundAction2,
        fetchTransactionDataPending,
        allData: {
            Transaction_Table: {
                Data: Transaction_Table_Data,
                Count: Transaction_Table_Count
            },
            Transaction_Table_Bought: {
                Data: Transaction_Table_Bought_Data,
                Count: Transaction_Table_Bought_Count,
            },
            Transaction_Table_Sold: {
                Data: Transaction_Table_Sold_Data,
                Count: Transaction_Table_Sold_Count
            },
        },
        fetchTransactionCountDataPending
    };
}
