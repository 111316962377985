import {
    FETCH_TRANSACTION_DATA_BEGIN,
    FETCH_TRANSACTION_DATA_FAILURE,
    FETCH_TRANSACTION_DATA_SUCCESS
} from "../../types";
export default (state, action) => {
    switch (action.type) {
        case FETCH_TRANSACTION_DATA_BEGIN:
            return {
                ...state,
                fetchTransactionDataPending: true,
            };

        case FETCH_TRANSACTION_DATA_FAILURE:
            return {
                ...state,
                fetchTransactionDataPending: false,
            };

        case FETCH_TRANSACTION_DATA_SUCCESS:
            return {
                ...state,
                [`${action.path}_Data`]:action.data,
                fetchTransactionDataPending: false,
            };

        default:
            return state;
    }
};
