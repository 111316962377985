import React, {useState} from "react";

import { makeStyles } from "@material-ui/core/styles";

// core components

import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Grid from "@material-ui/core/Grid";
import {TransactionChart} from "../../components/Charts/TransactionChart";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "../../components/Grid/GridItem";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
}));
export default function Signals() {
  const address = useSelector((state) => state.wallet.address);

  const [type, setType] = useState('net');
  const [time, setTime] = useState('day');

  const typeChange = (event) => {
    setType(event.target.value);
  };

  const timeChange = (event) => {
    setTime(event.target.value);
  };


  const classes = useStyles();
  return (
      <div>
        {address != "" &&

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Transaction Chart</h4>
              </CardHeader>
              <CardBody>

              <FormControl className={classes.formControl}>
                  <InputLabel id="input-data-chart-label">Chart Data</InputLabel>
                  <Select
                      labelId="select-data-chart"
                      id="data-chart"
                      value={type}
                      onChange={typeChange}
                  >
                    <MenuItem value={'net'}>Net</MenuItem>
                    <MenuItem value={'volume'}>Volume</MenuItem>
                    <MenuItem value={'sell'}>Sell</MenuItem>
                    <MenuItem  value={'buy'}>Buy</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="input-time-scale-label">Time Scale</InputLabel>
                  <Select
                      labelId="select-time-scale"
                      id="time-scale"
                      value={time}
                      onChange={timeChange}
                  >
                    <MenuItem value={'minute'}>Minute</MenuItem>
                    <MenuItem value={'hour'}>Hour</MenuItem>
                    <MenuItem value={'day'}>Day</MenuItem>
                    <MenuItem  value={'week'}>Week</MenuItem>
                    <MenuItem  value={'month'}>Month</MenuItem>
                  </Select>
                </FormControl>
                {/*<FormControl className={classes.formControl} >*/}
                {/*    <InputLabel style={{color: 'black'}} htmlFor="filled-age-native-simple">Transaction*/}
                {/*        Data</InputLabel>*/}
                {/*    <Select*/}
                {/*        native*/}
                {/*        value={type}*/}
                {/*        onChange={typeChange}*/}
                {/*        label="Type"*/}
                {/*        inputProps={{*/}
                {/*            name: 'type',*/}
                {/*            id: 'filled-age-native-simple',*/}
                {/*        }}*/}
                {/*        style={{background: 'white'}}*/}
                {/*    >*/}
                {/*        <option value={'net'}>Net</option>*/}
                {/*        <option value={'volume'}>Volume</option>*/}
                {/*        <option value={'sell'}>Sell</option>*/}
                {/*        <option value={'buy'}>Buy</option>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*<FormControl className={classes.formControl} >*/}
                {/*    <InputLabel style={{color: 'black'}} htmlFor="filled-age-native-simple">Time*/}
                {/*        Scale</InputLabel>*/}
                {/*    <Select*/}
                {/*        native*/}
                {/*        value={time}*/}
                {/*        onChange={timeChange}*/}
                {/*        label="Type"*/}
                {/*        inputProps={{*/}
                {/*            name: 'type',*/}
                {/*            id: 'filled-age-native-simple',*/}
                {/*        }}*/}
                {/*        style={{background: 'white'}}*/}
                {/*    >*/}
                {/*        <option value={'minute'}>Minute</option>*/}
                {/*        <option value={'hour'}>Hour</option>*/}
                {/*        <option value={'day'}>Day</option>*/}
                {/*        <option value={'week'}>Week</option>*/}
                {/*        <option value={'month'}>Month</option>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}

                <TransactionChart  time={time} type={type}/>
              </CardBody>
              {/*<CardFooter stats>*/}
              {/*    <div className={classes.stats}>*/}
              {/*        <Danger>*/}
              {/*            <Warning />*/}
              {/*        </Danger>*/}
              {/*        <a href="#pablo" onClick={e => e.preventDefault()}>*/}
              {/*            Get more space*/}
              {/*        </a>*/}
              {/*    </div>*/}
              {/*</CardFooter>*/}
            </Card>
          </GridItem>
        </GridContainer>
        }
      </div>
  );
}
