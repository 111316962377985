import {
    FETCH_SIGNAL_COUNT_BEGIN,
    FETCH_SIGNAL_COUNT_FAILURE,
    FETCH_SIGNAL_COUNT_SUCCESS
} from "../../types";
import {GetSignalsCount} from "../../../api/queries";

export function fetchSignalCount({pair, token, starting, threshold}) {
    return (dispatch) => {
        dispatch({
            type: FETCH_SIGNAL_COUNT_BEGIN
        });

        return new Promise((resolve, reject) => {
            GetSignalsCount(pair, token, starting, threshold)
                .then((result)=> {
                    dispatch({
                        type: FETCH_SIGNAL_COUNT_SUCCESS,
                        data: result
                    })
                    resolve(result);
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_SIGNAL_COUNT_FAILURE
                    });
                    console.log(error);
                    return reject(error.message || error);
                })
        })
    }
}
