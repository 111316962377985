/*!

=========================================================
* Material Signals React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import WarningIcon from '@material-ui/icons/Warning';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartIcon from '@material-ui/icons/TableChart';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AnnouncementIcon from '@material-ui/icons/Announcement';
// core components/views for Admin layout
import SignalPage from "views/Signals/Signals.js";
import Chart from "views/Chart/Chart.js";
import TableList from "views/Transaction/Transaction.js";
import Address from "./views/Address/Address";
import New from "views/New/New";
import DashboardOverview from "views/Dashboard/Dashboard";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import YourAccount from "./views/YourAccount/YourAccount";

// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardOverview,
    layout: "/admin"
  },
  {
    path: "/signals",
    name: "Signals",
    icon: WarningIcon,
    component: SignalPage,
    layout: "/admin"
  },
  {
    path: "/chart",
    name: "Chart",
    icon: BarChartIcon,
    component: Chart,
    layout: "/admin"
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: TableChartIcon,
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/address/:useraddress?",
    name: "Address",
    icon:PersonIcon,
    component: Address,
    layout: "/admin"
  },
  {
    path: "/new",
    name: "New Buyers/Sellers",
    icon:AnnouncementIcon,
    component: New,
    layout: "/admin"
  },
  {
    path: "/account",
    name: "Your Account",
    icon: AccessibilityIcon,
    component: YourAccount,
    layout: "/admin"
  },
];

export default dashboardRoutes;
