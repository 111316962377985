import {
    FETCH_SIGNAL_DATA_BEGIN,
    FETCH_SIGNAL_DATA_SUCCESS,
    FETCH_SIGNAL_DATA_FAILURE
} from "../../types";
export default (state, action) => {
    switch (action.type) {
        case FETCH_SIGNAL_DATA_BEGIN:
            return {
                ...state,
                fetchSignalDataPending: true,
            };

        case FETCH_SIGNAL_DATA_FAILURE:
            return {
                ...state,
                fetchSignalDataPending: false,
            };

        case FETCH_SIGNAL_DATA_SUCCESS:
            return {
                ...state,
                signalData:action.data,
                fetchSignalDataPending: false,
            };

        default:
            return state;
    }
};
