import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {infoColor} from "../../assets/jss/material-dashboard-react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import {useFetchTransactionData} from "../../store/hooks/vault/useFetchTransactionData";
import tokens from "../../contracts/tokens"
import {useFetchBalances} from "../../store/hooks/vault/useFetchBalances";
import {Signals_Table, Summary_Table} from "../../store/types";
import {useConnectWallet} from "../../store/hooks/wallet";
import styles2 from "assets/jss/material-dashboard-react/components/tableStyle.js";
import {TableCell, TableContainer, TableFooter, TablePagination, TextField} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TransactionRow} from "../../components/Rows/TransactionRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {SignalRow} from "../../components/Rows/SignalRow";
import {useFetchSignalData} from "../../store/hooks/vault/useFetchSignalData";
import {useChangeToken} from "../../store/hooks/globalSettings/useChangeToken";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

export default function Transaction() {
    const classes = useStyles();

    const [presetStarting, setPresetStarting] = useState(86400)
    const [customStarting, setCustomStarting] = useState(new Date())
    const [starting, setStarting] = useState(Math.round(new Date().getTime()/1000) - presetStarting)
    const [customFlag, setCustomFlag] = useState(false)
    const [threshold, setThreshold] = useState(250)
    const [tempThreshold, setTempThreshold] = useState(250)
    const {web3, address} = useConnectWallet();
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [addresses, setAddresses] = useState([])
    const {allBalances, fetchBalances, fetchBalancesPending} = useFetchBalances();
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(1)
    const [limit, setLimit] = useState(20)
    const {globalTokenAddress,globalPair}=useChangeToken()
    const balances = allBalances[Signals_Table];

    const {signalData,fetchSignalCountData, fetchSignalData,countSignalData}  = useFetchSignalData();

    useEffect(() => {
        if(web3!==null) {
            fetchBalances({
                addresses, web3, table: Signals_Table
            })
        }
    }, [addresses,web3])

    useEffect(() => {
        fetchSignalData({pair:globalPair, token:globalTokenAddress, starting, threshold,limit,page}).then(
            (e) => {
                setAddresses(e.map((a) => {
                    return a.address
                }))
            }
        )
        fetchSignalCountData({pair:globalPair, token:globalTokenAddress, starting,threshold})
    },[globalPair, globalTokenAddress, starting, threshold,limit,page]);

    useEffect(() => {
        setAddresses(signalData.map((a)=> {
            return a.address
        }));
        if(countSignalData.length!==0) {
            setCount(countSignalData[0].count)
        }
    },[signalData,countSignalData])


    const presetStartingChange = (event) => {
        setPresetStarting(event.target.value);
        if(event.target.value=== -1){
            setCustomFlag(true)
        }
        else if(event.target.value=== 0){
            setCustomFlag(false)
            setStarting(event.target.value);
        }
        else {
            setCustomFlag(false)
            setStarting(Math.round(new Date().getTime()/1000) - event.target.value);
        }
    };

    const customStartingChange = (event) => {
        setCustomStarting(event);
        if(customStarting)
            setStarting(Math.round(event.getTime()/1000))
        else
            setStarting(0)
    };

    const thresholdChange = (event) => {
        setThreshold(event.target.value);
    };

    const tempThresholdChange = (event) => {
        setTempThreshold(event.target.value)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setLimit(event.target.value)
        setPage(0);
    };

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Signal Table</h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={3}>
                            <Grid item lg={2}>
                                <FormControl className={classes.formControl}>
                                <TextField
                                    id="filled-number"
                                    label="Threshold"
                                    type="threshold"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={tempThreshold}
                                    onChange={tempThresholdChange}
                                    onBlur={thresholdChange}
                                    variant="outlined"
                                    style={{background:"white"}}
                                />
                                </FormControl>
                            </Grid>
                            <Grid item lg={2}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="input-time-scale-label">Starting</InputLabel>
                                    <Select
                                        labelId="select-time-scale"
                                        id="time-scale"
                                        value={presetStarting}
                                        onChange={presetStartingChange}
                                    >
                                        <MenuItem value={-1}>Custom</MenuItem>
                                        <MenuItem value={0}>All Time</MenuItem>
                                        <MenuItem value={3600}>Last Hour</MenuItem>
                                        <MenuItem value={14400}>Last 4 Hours</MenuItem>
                                        <MenuItem value={43200}>Last 12 Hours</MenuItem>
                                        <MenuItem value={86400}>Last Day</MenuItem>
                                        <MenuItem value={259200}>Last 3 Days</MenuItem>
                                        <MenuItem value={604800}>Last Week</MenuItem>
                                        <MenuItem value={1209600}>Last 2 Weeks</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                clearable
                                value={customStarting}
                                onChange={customStartingChange}
                                style={{
                                    borderRadius: 5,
                                    paddingTop: 10,
                                    background: "white",
                                    display: customFlag ? "inline-flex" : "none"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <TableContainer>
                            <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Signal</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Price($)</TableCell>


                                        <TableCell>Balance</TableCell>
                                        <TableCell>Vault Balance</TableCell>
                                        <TableCell>Transaction</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Starred</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {  Object.keys(balances).length !== 0 &&
                                    signalData.map((row) => (
                                        <SignalRow token={globalTokenAddress} row={row} balances={balances[row.address]}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    colSpan={3}
                                    count={(count)}
                                    rowsPerPage={rowsPerPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                />
                            </TableRow>
                        </TableFooter>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

