import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchStarred} from "../../actions/vault/fetchStarred";

export function useFetchStarred() {
    const dispatch = useDispatch();

    const {starred,fetchStarredPending,addressFavList} = useSelector(
        (state) => ({
            starred: state.vault.starred,
            fetchStarredPending: state.vault.fetchStarredPending,
            addressFavList: state.vault.addressFavList
        }),
        shallowEqual,
    );

    const boundAction = useCallback(
        (data) => {
            return dispatch(fetchStarred(data));
        },
        [dispatch],
    );


    return {
        starred,
        fetchStarred: boundAction,
        fetchStarredPending,
        addressFavList
    };
}
