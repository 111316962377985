import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {
    GetUserBalance,
    GetUserTransactions,
    GetUserTxCount,
} from "../../api/queries";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, Select, TableCell, TableContainer, TableFooter, TablePagination} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "../../components/Card/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import {useConnectWallet} from "../../store/hooks/wallet";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import {UserTransactionTableRow} from "../../components/Rows/UserTransactionTableRow";
import TableBody from "@material-ui/core/TableBody";
import UserChartTransaction from "../../components/Charts/UserChartTransaction";
import {useChangeToken} from "../../store/hooks/globalSettings/useChangeToken";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
}));

export default function Address() {
    const classes = useStyles();

    const {useraddress} = useParams();

    const { web3 } = useConnectWallet()
    const [balance, setBalance] = useState()
    const [type, setType] = useState('net');
    const [time, setTime] = useState('day');
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(1)
    const [limit, setLimit] = useState(10)
    const [address,setAddress] = useState('')
    const {globalTokenAddress,globalPair}=useChangeToken()

    const getData = (address) => {
        GetUserBalance(globalTokenAddress, address).then(r =>
        {
            setBalance(r.result / (10 ** 18))
        })
    }

    const getTableData = (address, globalPair, globalTokenAddress, limit, offset) => {
        GetUserTransactions(address, globalPair, globalTokenAddress, limit, offset).then(r => setRows(r))
    }
    const getUserTxCount = (globalPair,globalTokenAddress,user) => {
        GetUserTxCount(globalPair, globalTokenAddress,user).then(r => setCount(r[0].count))
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setLimit(event.target.value)
        setPage(0);
    };

    const addressChange = (add) => {
        if(web3!==null ){
            if(web3.utils.isAddress(add)){
                setAddress(add)
            }
            else{
                setAddress('')
            }
        }
    }

    const getBalance = () => {
        if(!isNaN(balance)){
            return balance
        }
    }

    const typeChange = (event) => {
        setType(event.target.value);
    };

    const timeChange = (event) => {
        setTime(event.target.value);
    };




    useEffect(() => {
        getTableData(address, globalPair, globalTokenAddress, limit, page)
    }, [globalPair,globalTokenAddress,page, limit,address])

    useEffect(() => {
        getUserTxCount(globalPair, globalTokenAddress, address)
    }, [globalPair, globalTokenAddress, address])

    useEffect(() => {
        if(useraddress!==":useraddress"){
            setAddress(useraddress)
        }
        else{
            setAddress('')
        }
    },[useraddress])

    useEffect(() => {
        getData(address)
    },[globalPair,globalTokenAddress,address])

    useEffect(() => {
        if(isNaN(balance)){
            getData(address)
        }
    },[globalPair,globalTokenAddress,balance])

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <SearchBar
                value={address}
                onChange={(newValue) => addressChange(newValue)}
                onRequestSearch={(newValue) => addressChange(newValue)}
                onCancelSearch={(()=> setAddress(""))}
                style={{marginBottom:10}}
            />
            </GridItem>
            {address !== '' &&
                <>
            <GridItem xs={12} sm={8} md={8}>
                <Typography variant='p' style={{color: '#555555'}}>Address: <a
                    href={'https://bscscan.com/address/' + address}>{address}</a></Typography>
            </GridItem>
                    <GridItem xs={12} sm={4} md={4} >
                        <span style={{color: '#555555'}}>Balance: {getBalance()} ACS </span>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>User Transaction Table</h4>
                            </CardHeader>
                            <CardBody>
                                <Typography variant='h5' style={{color: 'white'}}>Latest Transactions:</Typography>
                                <TableContainer>
                                    <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Transaction</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Value</TableCell>
                                                <TableCell>Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <UserTransactionTableRow globalTokenAddress={globalTokenAddress} key={row.address} row={row}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, {label: 'All', value:0}]}
                                            colSpan={3}
                                            count={(count)} //to make it an int
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                native: true,
                                            }}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </CardBody>
                        </Card>
                    </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>User Transaction Chart</h4>
                    </CardHeader>
                    <CardBody>
                        <Typography variant='h5' style={{color: 'white'}}>Volume Chart:</Typography>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="input-data-chart-label">Chart Data</InputLabel>
                                <Select
                                    labelId="select-data-chart"
                                    id="data-chart"
                                    value={type}
                                    onChange={typeChange}
                                >
                                    <MenuItem value={'net'}>Net</MenuItem>
                                    <MenuItem value={'volume'}>Volume</MenuItem>
                                    <MenuItem value={'sell'}>Sell</MenuItem>
                                    <MenuItem  value={'buy'}>Buy</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="input-time-scale-label">Time Scale</InputLabel>
                                <Select
                                    labelId="select-time-scale"
                                    id="time-scale"
                                    value={time}
                                    onChange={timeChange}
                                >
                                    <MenuItem value={'minute'}>Minute</MenuItem>
                                    <MenuItem value={'hour'}>Hour</MenuItem>
                                    <MenuItem value={'day'}>Day</MenuItem>
                                    <MenuItem  value={'week'}>Week</MenuItem>
                                    <MenuItem  value={'month'}>Month</MenuItem>
                                </Select>
                            </FormControl>
                        <UserChartTransaction address={address} time={time} type={type}/>
                    </CardBody>
                </Card>
            </GridItem>
                </>
                }
        </GridContainer>
    )
}
