import axios from "axios";

import {axiosURL,axiosFlaskURL,axiosAuthURL} from "../config/axios";
import { applyAuthTokenInterceptor,setAuthTokens, getAccessToken, getRefreshToken } from 'axios-jwt';

const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` }
};

const GetTransactions = (pair, token, limit, offset, starting, sortBy, blacklist=0) => {
    let req = '/transactions';

    return new Promise((resolve, reject) => {
        axiosURL
            .post(req, {
                    'token': token,
                    'pair': pair,
                    'limit': limit,
                    'offset': offset,
                    'starting': starting,
                    'sortBy': sortBy,
                    'blacklist':blacklist

            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetTVL = () => {
    let req = '/getTVL';

    return new Promise((resolve,reject) => {
        axiosFlaskURL
            .get(req).then((res) => {
                resolve(res.data)
        }).catch((err)=> {
            reject(err);
        })
    })
}
const GetVenusTVL = () => {
    let req = '/getVenusTVL';

    return new Promise((resolve,reject) => {
        axiosFlaskURL
            .get(req).then((res) => {
                resolve(res.data)
        }).catch((err)=> {
            reject(err);
        })
    })
}
const GetTopHolders = (tokenAddress) => {
    let req = `/getTopHolders/${tokenAddress}`;

    return new Promise((resolve,reject) => {
        axiosFlaskURL
            .get(req).then((res) => {
                resolve(res.data)
        }).catch((err)=> {
            reject(err);
        })
    })
}
const GetDailyStats = (tokenAddress) => {
    let req = '/getDailyStats';

    return new Promise((resolve,reject) => {
        axiosFlaskURL
            .get(req).then((res) => {
                resolve(res.data)
        }).catch((err)=> {
            reject(err);
        })
    })
}

const GetUserBalance = (token, user) => {
    let req = '/balance';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {
                    'token': token,
                    'user': user,
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetUserTransactions = (user, pair, token, limit, offset) => {
    let req = '/usertransactions';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {
                    'token': token,
                    'user': user,
                    'pair': pair,
                    'limit': limit,
                    'offset': offset
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetNetChartData = (time, pair, token, min, max) => {
    let req = '/netchart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetVolumeChartData = (time, pair, token, min, max) => {
    let req = '/volchart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetBuyChartData = (time, pair, token, min, max) => {
    let req = '/buychart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetSellChartData = (time, pair, token, min, max) => {
    let req = '/sellchart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// const GetUserChartData = (user, time, pair, token, min, max) => {
//     let req=  '/getchart' ;

//     return new Promise((resolve, reject) => {
//         axiosURL
//             .get(req,{ params : {
//                     'token': token,
//                     'pair': pair,
//                     'time': time,
//                     'min': min,
//                     'max': max,
//                     'user': user
//                 }
//             })

//             .then((res) => {
//                 let result = res.data;
//                 resolve(result);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// };
const GetUserNetChartData = (user, time, pair, token, min, max) => {
    let req = '/usernetchart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max,
                    'user': user
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetUserVolumeChartData = (user, time, pair, token, min, max) => {
    let req = '/uservolchart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max,
                    'user': user
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetUserBuyChartData = (user, time, pair, token, min, max) => {
    let req = '/userbuychart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max,
                    'user': user
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const GetUserSellChartData = (user, time, pair, token, min, max) => {
    let req = '/usersellchart';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req, {
                params: {

                    'time': time,
                    'token': token,
                    'pair': pair,
                    'min': min,
                    'max': max,
                    'user': user
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetTransactionCount = (pair, token, starting,blacklist=0) => {
    let req = '/count';

    return new Promise((resolve, reject) => {
        axiosURL
            .post(req, {
                    'token': token,
                    'pair': pair,
                    'starting': starting,
                    'blacklist':blacklist

            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetUserTxCount = (pair, token, user) => {
  let req = '/usertxcount';
  return new Promise((resolve, reject) => {
      axiosURL
          .get(req, {
              params: {
                  'token': token,
                  'pair': pair,
                  'user': user
              }
          })

          .then((res) => {
              let result = res.data;
              resolve(result);
          })
          .catch((err) => {
              reject(err);
          });
  });
};

const GetAddressVerification = async (address) => {
    let req = "/verifyaddress"
    return new Promise((resolve,reject)=> {
        axiosURL.get(req,{
            params:{
                "address":address
            }
        }).then((res)=>{
            let result = res.data;
            resolve(result)
        }).catch((err)=> {
            reject(err)
        })
    })
}

const GetPrice = async (time, token) => {
    try {
        const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${token}/market_chart/range`, {
            params: {
                from: parseInt(time) - 60,
                to: parseInt(time) + 60,
                vs_currency: 'usd'
            }
        })
        return response.data.prices.length > 0 ? response.data.prices[0][1] : 0
    } catch (error) {
        console.log(error)
    }
}

const GetSignals = (pair, token, starting, threshold,limit,page) => {
    let req=  '/signals' ;
    return new Promise((resolve, reject) => {
        axiosURL
            .get(req,{ params : {
                    'token': token,
                    'pair': pair,
                    'starting': starting,
                    'threshold': threshold,
                    'limit': limit,
                    'offset': page
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetSignalsCount = (pair, token, starting, threshold) => {
  let req=  '/signalscount' ;

  return new Promise((resolve, reject) => {
      axiosURL
          .get(req,{ params : {
                  'token': token,
                  'pair': pair,
                  'starting': starting,
                  'threshold': threshold
              }
          })

          .then((res) => {
              let result = res.data;
              resolve(result);
          })
          .catch((err) => {
              reject(err);
          });
  });
};
const GetBNBinfo = () => {
    let req = '/bnbinfo';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req).then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetACSinfo = () => {
    let req = '/acsinfo';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req).then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetACSIinfo = () => {
    let req = '/acsiinfo';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req).then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetPancakeinfo = () => {
    let req = '/pancakeinfo';

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req).then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const GetNewBuyers = (pair, token, starting, limit,offset) => {
    let req=  '/newbuys' ;

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req,{ params : {
                    'token': token,
                    'pair': pair,
                    'starting': starting,
                    'limit': limit,
                    'offset':offset
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
  };
  const GetNewSellers = (pair, token, starting, limit,offset) => {
    let req=  '/newsells' ;

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req,{ params : {
                    'token': token,
                    'pair': pair,
                    'starting': starting,
                    'limit': limit,
                    'offset':offset
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
  };
  const GetNewBuyersCount = (pair, token, starting) => {
    let req=  '/newbuyscount' ;

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req,{ params : {
                    'token': token,
                    'pair': pair,
                    'starting': starting,
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
  };
  const GetNewSellersCount = (pair, token, starting) => {
    let req=  '/newsellscount' ;

    return new Promise((resolve, reject) => {
        axiosURL
            .get(req,{ params : {
                    'token': token,
                    'pair': pair,
                    'starting': starting,
                }
            })

            .then((res) => {
                let result = res.data;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
  };

  const GetAddress = () => {
      let req= "/getaddresses"

      return new Promise((resolve, reject) =>{
          axiosURL.get(req).then((res) => {
              let result = res.data;
              resolve(result);
          }).catch((err)=>{
              reject(err)
          })
      })
  }


  const PostAddAddress = (address,description="",isadmin=0) => {
    let req = "/addaddress"
    console.log(address,description,isadmin)
    return new Promise((resolve, reject) => {
        axiosURL.post(req,{
            "address":address,
            "description":description,
            "isadmin":isadmin
        }).then((res) => {
            let result = res.data;
            resolve(result);
        }).catch((err)=>{
            reject(err)
        })
    } )
}

const PostDeleteAddress = (address) => {
    let req = "/deleteaddress"
    return new Promise((resolve, reject) => {
        axiosURL.post(req,{
            "address":address,
        }).then((res) => {
            let result = res.data;
            resolve(result);
        }).catch((err)=>{
            reject(err)
        })
    } )
}

const GetFavorites = (address) => {
    let req = "/getfavorite"
    return new Promise((resolve, reject) => {
        axiosURL.post(req,{
                "user": address,

        }).then((res) => {
            let result = res.data;
            resolve(result);
        }).catch((err)=>{
            reject(err)
        })
    } )
}

const PostAddFavorite = (address,addressStar,description) => {
    let req = "/addfavorite"
    return new Promise((resolve, reject) => {
        axiosURL.post(req,{
            "user": address,
            "favorite":addressStar,
            "description":description

        }).then((res) => {
            let result = res.data;
            resolve(result);
        }).catch((err)=>{
            reject(err)
        })
    } )
}

const PostDeleteFavorite = (address,addressStar) => {
    let req = "/deletefavorite"
    return new Promise((resolve, reject) => {
        axiosURL.post(req,{
            "user": address,
            "favorite":addressStar,

        }).then((res) => {
            let result = res.data;
            resolve(result);
        }).catch((err)=>{
            reject(err)
        })
    } )
}
const GetToken=(address,signed)=>{
    let req = "/token"
    return new Promise((resolve, reject) => {
        axiosAuthURL.post(req,{
            "address": address,
            "signed":signed,

        }).then((res) => {
            let result = res.data;
            setAuthTokens({
                accessToken: res.data.token,
                refreshToken: res.data.refreshToken
              })
            resolve(result);
        }).catch((err)=>{
            reject(err)
        })
    } )
}
const refresh = getRefreshToken()
const requestRefresh = (refresh) => {
    // Notice that this is the global axios instance, not the axiosInstance!  <-- important
    return axios.post(`https://api.blockpearl.finance/auth/refresh`, {refresh})
      .then(response =>{return (response.data.token)})
};
applyAuthTokenInterceptor(axiosURL, { requestRefresh });

export {
    GetTransactionCount,
    GetTransactions,
    GetUserBalance,
    GetUserTransactions,
    GetNetChartData,
    GetVolumeChartData,
    GetBuyChartData,
    GetSellChartData,
    GetPrice,
    GetUserBuyChartData,
    GetUserNetChartData,
    GetUserSellChartData,
    GetUserVolumeChartData,
    GetUserTxCount,
    GetSignals,
    GetSignalsCount,
    GetBNBinfo,
    GetACSinfo,
    GetACSIinfo,
    GetPancakeinfo,
    GetNewSellers,
    GetNewBuyers,
    GetNewBuyersCount,
    GetNewSellersCount,
    GetTVL,
    GetTopHolders,
    GetDailyStats,
    GetAddressVerification,
    GetAddress,
    PostAddAddress,
    GetVenusTVL,
    PostDeleteAddress,
    GetFavorites,
    PostAddFavorite,
    PostDeleteFavorite,
    GetToken,
    requestRefresh
};
