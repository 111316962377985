import {
    FETCH_SIGNAL_DATA_BEGIN,
    FETCH_SIGNAL_DATA_FAILURE,
    FETCH_SIGNAL_DATA_SUCCESS
} from "../../types";
import {GetSignals} from "../../../api/queries";

export function fetchSignalData({pair, token, starting, threshold,limit,page}) {
    return (dispatch) => {
        dispatch({
            type: FETCH_SIGNAL_DATA_BEGIN
        });
        return new Promise((resolve, reject) => {
            GetSignals(pair, token, starting, threshold,limit,page)
                .then((result)=> {
                    dispatch({
                        type: FETCH_SIGNAL_DATA_SUCCESS,
                        data: result
                    })
                    resolve(result);
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_SIGNAL_DATA_FAILURE
                    });
                    console.log(error);
                    return reject(error.message || error);
                })
        })
    }
}
