import React from "react";
import TransactionTable from "../../components/Table/TransactionTable";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Transaction_Table} from "../../store/types";


export default function Transaction() {

  return (

        <TransactionTable bought={true} sold={true} table={Transaction_Table} title={"Transaction Summary"}/>

  )
}
