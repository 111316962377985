import {FETCH_TOKEN_PRICE_PER_FULL_SHARE_SUCCESS,FETCH_TOKEN_PRICE_PER_FULL_SHARE_BEGIN,FETCH_TOKEN_PRICE_PER_FULL_SHARE_FAILURE} from "../../types/vault";

export default (state, action) => {
    switch (action.type) {
        case FETCH_TOKEN_PRICE_PER_FULL_SHARE_BEGIN:
            return {
                ...state,
                fetchTokenPending: true,
            };

        case FETCH_TOKEN_PRICE_PER_FULL_SHARE_FAILURE:
            return {
                ...state,
                fetchTokenPending: false,
            };

        case FETCH_TOKEN_PRICE_PER_FULL_SHARE_SUCCESS:
            return {
                ...state,
                tokenData:action.data,
                fetchTokenPending: false,
            };

        default:
            return state;
    }
};
