export const FETCH_INFO_BEGIN = "FETCH_INFO_BEGIN";
export const FETCH_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_INFO_FAILURE = "FETCH_INFO_FAILURE";

export const FETCH_BNB_INFO_BEGIN = "FETCH_INFO_BEGIN";
export const FETCH_BNB_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_BNB_INFO_FAILURE = "FETCH_INFO_FAILURE";

export const FETCH_CAKE_INFO_BEGIN = "FETCH_INFO_BEGIN";
export const FETCH_CAKE_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_CAKE_INFO_FAILURE = "FETCH_INFO_FAILURE";

export const FETCH_ACS_INFO_BEGIN = "FETCH_INFO_BEGIN";
export const FETCH_ACS_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_ACS_INFO_FAILURE = "FETCH_INFO_FAILURE";

export const FETCH_ACSI_INFO_BEGIN = "FETCH_INFO_BEGIN";
export const FETCH_ACSI_INFO_SUCCESS = "FETCH_INFO_SUCCESS";
export const FETCH_ACSI_INFO_FAILURE = "FETCH_INFO_FAILURE";
