import {
    FETCH_CAKE_INFO_BEGIN,
    FETCH_CAKE_INFO_FAILURE,
    FETCH_CAKE_INFO_SUCCESS
} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case FETCH_CAKE_INFO_BEGIN:
            return {
                ...state,
                fetchPancakeDataPending: true,
            };

        case FETCH_CAKE_INFO_FAILURE:
            return {
                ...state,
                fetchPancakeDataPending: false,
            };

        case FETCH_CAKE_INFO_SUCCESS:
            return {
                ...state,
                pancakeData:action.data,
                fetchPancakeDataPending: false,
            };

        default:
            return state;
    }
};
