import React, {useEffect, useState} from "react";
// @material-ui/core components
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {infoColor} from "../../assets/jss/material-dashboard-react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import tokens from "../../contracts/tokens"
import {useFetchBalances} from "../../store/hooks/vault/useFetchBalances";
import {Buyer_Table, Seller_Table, Transaction_Table_Bought, Transaction_Table_Sold} from "../../store/types";
import {useConnectWallet} from "../../store/hooks/wallet";
import {
    createMuiTheme,
    FormControlLabel, FormLabel,
    Radio, RadioGroup,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {NewRow} from "../../components/Rows/NewRow";
import {HoldersRow} from "../../components/Rows/HoldersRow";
import {StatsRow} from "../../components/Rows/StatsRow";


import{GetNewBuyers,GetNewSellers,GetNewBuyersCount,GetNewSellersCount,GetTopHolders,GetDailyStats} from "../../api/queries"
import {useFetchToken} from "../../store/hooks/vault/useFetchToken";
import {acsAcsiToken} from "../../contracts/tokens/acsAsciToken";
import {acsAcsToken} from "../../contracts/tokens/acsAcsToken";
import {useChangeToken} from "../../store/hooks/globalSettings/useChangeToken";
import {acsToken} from "../../contracts/tokens/acsToken";
import {acsiToken} from "../../contracts/tokens/acsiToken";
import TransactionTable from "../../components/Table/TransactionTable";

const useStyle = makeStyles((theme) => ({
        cardCategoryWhite: {
            "&,& a,& a:hover,& a:focus": {
                color: "rgba(255,255,255,.62)",
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0"
            },
            "& a,& a:hover,& a:focus": {
                color: "#FFFFFF"
            }
        },
        cardTitleWhite: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: "300",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        radio: {
            '&$checked': {
                color: infoColor[0]
            }
        }
    }
))

const theme = createMuiTheme({
    palette: {
        primary: { main: infoColor[0] }, // Purple and green play nicely together.
    },
});


export default function DashboardOverview() {
    const classes = useStyle();

    const {web3, address} = useConnectWallet();
    const {balance_Seller_Dashboard_Table:balances_Seller,
        balance_Buyer_Dashboard_Table: balances_Buyer,
        fetchBalances, fetchBalancesPending} = useFetchBalances();
    const {fetchToken} = useFetchToken()
    const {globalTokenAddress,globalPair}=useChangeToken()

    const [presetStarting, setPresetStarting] = useState(86400)
    const [customStarting, setCustomStarting] = useState(new Date())
    const [starting, setStarting] = useState(Math.round(new Date().getTime()/1000) - presetStarting)
    const [customFlag, setCustomFlag] = useState(false)
    const [presetStarting2, setPresetStarting2] = useState(86400)
    const [customStarting2, setCustomStarting2] = useState(new Date())
    const [starting2, setStarting2] = useState(Math.round(new Date().getTime()/1000) - presetStarting)
    const [customFlag2, setCustomFlag2] = useState(false)
    const [tempThreshold, setTempThreshold] = useState(250)
    const [tempThreshold2, setTempThreshold2] = useState(250)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsPerPage2, setRowsPerPage2] = useState(10);
    const [addressesSellers, setAddressesSellers] = useState([])
    const [addressesBuyers, setAddressesBuyers] = useState([])
    const [page, setPage] = useState(0)
    const [page2, setPage2] = useState(0)
    const [page3, setPage3] = useState(0)
    const [page4, setPage4] = useState(0)
    const [buyersCount, setBuyersCount] = useState(0)
    const [buyersTableData,setBuyersTableData]  = useState([]);
    const [sellersCount, setSellersCount] = useState(0)
    const [sellersTableData,setSellersTableData]  = useState([]);
    const [limit, setLimit] = useState(10)
    const [limit2, setLimit2] = useState(10)
    const [selectedValue, setSelectedValue] = React.useState(acsToken.tokenAddress);

    const [operation,setOperation]=useState(0)
    const [operation2,setOperation2]=useState(0)

    const[dailystats,setDailystats]=useState([])
    const [topholders, setTopHolders] = useState([])

    useEffect(() => {
        if(web3!==null) {
            fetchBalances({
                addresses:addressesSellers, web3, table: Seller_Table
            })
            fetchBalances({
                addresses:addressesBuyers, web3, table: Buyer_Table
            })
            fetchToken({
                web3,tokens:[acsAcsToken,acsAcsiToken]
            })
        }
    }, [addressesSellers,addressesBuyers,web3])

    useEffect(()=>{
        GetTopHolders(selectedValue).then(x=>{
            let arr =x.data.filter(y=>{
                return y.address.charAt(0)==='0'
            })
            setTopHolders(arr)
        })
    },[selectedValue])
    useEffect(() => {
        GetDailyStats().then(x=>{
            setDailystats(x.data)
        })

        GetNewSellers(globalPair, globalTokenAddress, starting,limit,page).then(
            (e) => {
                setSellersTableData(e)
                setAddressesSellers(e.map((a) => {
                    return a.address
                }))

            }
        );
        GetNewSellersCount(globalPair, globalTokenAddress, starting).then(e=>{
            setSellersCount(e[0].count)
        });

        GetNewBuyers(globalPair, globalTokenAddress, starting2,limit2,page2).then(
                (e) => {
                    setBuyersTableData(e)
                    setAddressesBuyers(e.map((a) => {
                        return a.address
                    }))

                }
            )
        GetNewBuyersCount(globalPair, globalTokenAddress, starting2).then(e=>{
                setBuyersCount(e[0].count)
            })
        }

    ,[globalPair, globalTokenAddress, starting,limit,page,operation,starting2,limit2,page2]);


    const presetStartingChange = (event) => {
        setPresetStarting(event.target.value);
        if(event.target.value=== -1){
            setCustomFlag(true)
        }
        else if(event.target.value=== 0){
            setCustomFlag(false)
            setStarting(event.target.value);
        }
        else {
            setCustomFlag(false)
            setStarting(Math.round(new Date().getTime()/1000) - event.target.value);
        }
    };

    const customStartingChange = (event) => {
        setCustomStarting(event);
        if(customStarting)
            setStarting(Math.round(event.getTime()/1000))
        else
            setStarting(0)
    };

    const operationChange = (event) => {
        setOperation(0)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setLimit(event.target.value)
        setPage(0);

    };

    const presetStartingChange2 = (event) => {
        setPresetStarting2(event.target.value);
        if(event.target.value=== -1){
            setCustomFlag(true)
        }
        else if(event.target.value=== 0){
            setCustomFlag2(false)
            setStarting2(event.target.value);
        }
        else {
            setCustomFlag2(false)
            setStarting2(Math.round(new Date().getTime()/1000) - event.target.value);
        }
    };

    const customStartingChange2 = (event) => {
        setCustomStarting2(event);
        if(customStarting2)
            setStarting2(Math.round(event.getTime()/1000))
        else
            setStarting2(0)
    };

    const operationChange2 = (event) => {
        setOperation2(0)
    };

    const handleChangePage2= (event, newPage) => {
        setPage2(newPage);
    };
    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setLimit2(event.target.value)
        setPage2(0);

    };

    const tokenIds = tokens.map((token) => {
        return `${token[0].id}(${token[1].id})`
    });

    const handleTokenChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (

        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                <TransactionTable bought={true} sold={true} table={Transaction_Table_Bought} operation={"total_bought"} defaultTime={259200} title={"Buyers Summary"}/>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <TransactionTable bought={true} sold={true} table={Transaction_Table_Sold} operation={"total_sold"} defaultTime={259200} title={"Sellers Summary"}/>
            </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <Card>
                <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>Top Holders</h4>
                </CardHeader>
                <CardBody>
                    <MuiThemeProvider theme={theme}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Choose the Token</FormLabel>
                        <RadioGroup className={classes.radio} row aria-label="position" name="position" defaultValue={acsToken.tokenAddress} onChange={handleTokenChange}>
                            <FormControlLabel value={acsToken.tokenAddress} control={<Radio color="primary"/>} label="ACS" />
                            <FormControlLabel value={acsiToken.tokenAddress} control={<Radio color="primary"/>} label="ACSI" />
                            <FormControlLabel value={acsAcsToken.tokenAddress} control={<Radio color="primary"/>} label="acsACS" />
                            <FormControlLabel value={acsAcsiToken.tokenAddress} control={<Radio color="primary"/>} label="acsACSI" />
                        </RadioGroup>
                    </FormControl>
                    </MuiThemeProvider>
                    <TableContainer>
                        <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                topholders.map((row) => (
                                     <HoldersRow key={row.address} row={row}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                colSpan={3}
                                count={topholders.length}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </CardBody>
            </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <Card>
                <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>Daily Buybacks</h4>
                </CardHeader>
                <CardBody>
                    <TableContainer>
                        <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                dailystats.map((row) => (
                                     <StatsRow key={row.date} row={row}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                colSpan={3}
                                count={dailystats.length}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
    );
}

