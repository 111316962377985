import {
    FETCH_TOKEN_PRICE_PER_FULL_SHARE_SUCCESS,
    FETCH_TOKEN_PRICE_PER_FULL_SHARE_FAILURE,
    FETCH_TOKEN_PRICE_PER_FULL_SHARE_BEGIN
} from "../../types/vault";
import {MultiCall} from 'eth-multicall';
import {vaultABI} from '../../../contracts/ABI';
import BigNumber from "bignumber.js";

export function byDecimals(number, tokenDecimals = 18) {
    const decimals = new BigNumber(10).exponentiatedBy(tokenDecimals);
    return new BigNumber(number).dividedBy(decimals);
}
export function fetchToken({web3,tokens}) {
    return (dispatch) => {
        dispatch({
            type: FETCH_TOKEN_PRICE_PER_FULL_SHARE_BEGIN,
        });

        return new Promise((resolve, reject) => {
            const multicall = new MultiCall(
                web3,
                '0x964c522313a8dd1f7c1d69f3528ae10ff52ff438',
            );


            const calls = tokens.map((token) => {
                const vault = new web3.eth.Contract(
                    vaultABI,
                    token.tokenAddress,
                );


                return {
                    token: token.id,
                    pricePerFullShare: vault.methods.getPricePerFullShare(),
                    address: token.tokenAddress
                };
            });

            multicall
                .all([calls])
                .then((results) => {
                    const obj = {};
                    results[0].map((res) => {
                        obj[res.token] = {
                            address: res.address,
                            pricePerFullShare: byDecimals(
                                res.pricePerFullShare,
                                18,
                            ).toNumber()
                        }
                    });
                    dispatch({
                        type: FETCH_TOKEN_PRICE_PER_FULL_SHARE_SUCCESS,
                        data: obj
                    });
                    resolve(obj);

                }).catch((error) => {
                    console.log(error)
                dispatch({
                    type: FETCH_TOKEN_PRICE_PER_FULL_SHARE_FAILURE,
                });
                return reject(error.message || error);
            });
        });
    }
}
