import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchBalances} from '../../actions/vault/fetchBalances';

export function useFetchBalances() {
    const dispatch = useDispatch();

    const {fetchBalancesPending,Summary_Table,Signals_Table,
        News_Table,Seller_Dashboard_Table,Buyer_Dashboard_Table,
        Transaction_Table_Sold,Transaction_Table_Bought,Transaction_Table
    } = useSelector(
        (state) => ({
            fetchBalancesPending: state.vault.fetchBalancesPending,
            Summary_Table: state.vault.Summary_Table_Balance,
            Signals_Table: state.vault.Signals_Table_Balance,
            News_Table: state.vault.News_Table_Balance,
            Seller_Dashboard_Table: state.vault.Seller_Dashboard_Table_Balance,
            Buyer_Dashboard_Table: state.vault.Buyer_Dashboard_Table_Balance,
            Transaction_Table_Sold: state.vault.Transaction_Table_Sold_Balance,
            Transaction_Table_Bought: state.vault.Transaction_Table_Bought_Balance,
            Transaction_Table: state.vault.Transaction_Table_Balance,
        }),
        shallowEqual,
    );

    const boundAction = useCallback(
        (data) => {
            return dispatch(fetchBalances(data));
        },
        [dispatch],
    );

    return {
        allBalances:{
            Summary_Table,
            Signals_Table,
            News_Table,
            Seller_Dashboard_Table,
            Buyer_Dashboard_Table,
            Transaction_Table,
            Transaction_Table_Sold,
            Transaction_Table_Bought,
        },
        fetchBalances: boundAction,
        fetchBalancesPending,
    };
}
