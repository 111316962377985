import React, {useEffect, useState} from "react";
import {
    GetAddress,
    GetAddressVerification, PostAddAddress, PostAddFavorite, PostDeleteAddress, PostDeleteFavorite,
} from "../../api/queries";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
    Checkbox,
    FormControlLabel,
    TableCell,
    TableContainer,
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {useConnectWallet} from "../../store/hooks/wallet";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {useChangeToken} from "../../store/hooks/globalSettings/useChangeToken";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {useFetchStarred} from "../../store/hooks/vault/useFetchStarred";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        margin: theme.spacing(1),
    },
    field:{
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }

}));



export default function YourAccount() {
    const classes = useStyles();
    const { starred,fetchStarred,addressFavList } = useFetchStarred()

    const { address, web3 } = useConnectWallet()
    const [account,setAccount] = useState([]);
    const [userList,setUserList] = useState([])
    const [addressAddStatus,setAddressAddStatus] = useState(false)
    const [addressAddDescription,setAddressAddDescription] = useState("")
    const [addressChange,setAddressChange] = useState("")
    const [error,setError] = useState("")
    const [addressFavChange,setAddressFavChange] = useState("")
    const [addressFavDescription,setAddressFavDescription] = useState("")
    const [errorFav,setErrorFav] = useState("")
    const [addressList,setAddressList] = useState([])

    useEffect(()=>{
        GetAddressVerification(address).then((res)=>setAccount(res))
    },[])

    useEffect(()=>{
        if(account.length!==0){
            if(account[0].isadmin===1){
                GetAddress().then((res)=>{
                    onAddressListChange(res)
                })
            }
        }
    },[account])

    const onAddressListChange = (res) => {
        const temp = res.map((e)=>{
            return {
                address:e.address,
                status: e.isadmin,
                description: e.description
            }
        })
        console.log(temp)
        temp.sort(function (a,b){
            return b.status - a.status
        })
        setUserList(temp)
        setAddressList(res.map((e)=>{
            return e.address
        }))
    }
    const isAdmin = () => {
        if(account.length!==0){
            if(account[0].isadmin===1){
                return true
            }
        }
        return false
    }

    const onChange = (e) => {
        setAddressChange(e.target.value)
        if(web3!==null ){
            console.log(e.target.value.toLowerCase())
            console.log(userList)
            if(web3.utils.isAddress(e.target.value)){
                if(!addressList.includes(e.target.value.toLowerCase())){
                    setError("")
                }
                else{
                    setError("Address Already Allowed")
                }
            }
            else if(e.target.value===""){
                setError("")
            }
            else{
                setError("Not A Valid Address")
            }
        }
    }

    const onSubmit = () => {
        let admin = 0;
        if(addressAddStatus){
            admin=1
        }
        PostAddAddress(addressChange,addressAddDescription,admin).then((r) => {
            if(r.status){
                if(r.status===1){
                    GetAddress().then((res)=>{
                        onAddressListChange(res)

                    })
                    setAddressChange("")
                    setAddressAddDescription("")
                    setAddressAddStatus(false)
                }
            }
        } )
    }

    const userStatus = (status) => {
        if(status===1){
            return "Admin"
        }
        return "Guest"
    }

    const onChangeFav = (e) => {
        setAddressFavChange(e.target.value)
        if(web3!==null ){
            if(web3.utils.isAddress(e.target.value)){
                if(!addressFavList.includes(e.target.value.toLowerCase())){
                    setErrorFav("")
                }
                else{
                    setErrorFav("Address Already Allowed")
                }
            }
            else if(e.target.value===""){
                setErrorFav("")
            }
            else{
                setErrorFav("Not A Valid Address")
            }
        }
    }

    const onSubmitFav = () => {
        PostAddFavorite(address,addressFavChange,addressFavDescription).then((r) => {
                    fetchStarred({address})
                    setAddressFavChange("")
                    setAddressFavDescription("")

        } )
    }

    const onDelete = (index) => {
        PostDeleteAddress(index).then((r) => {
            if (r.status) {
                if (r.status === 1) {
                    GetAddress().then((res) => {
                        onAddressListChange(res)

                    })
                }
            }
        })
    }

    const onDeleteFavorite = (index) => {
        PostDeleteFavorite(address,index).then((r) => {
            fetchStarred({address})
        })
    }
    return (

        <GridContainer>
            {isAdmin() && <>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Addresses Allowed</h4>
                    </CardHeader>
                    <CardBody>
                            <TextField
                                error={error!==""}
                                id="addingAddress"
                                onChange={onChange}
                                label="Add Address"
                                helperText={error}
                                variant="outlined"
                                value={addressChange}
                            />
                            {error === "" && addressChange !== "" &&
                            <>
                                <FormControlLabel
                                    className={classes.button}
                                    control={<Checkbox checked={addressAddStatus} onChange={()=> {setAddressAddStatus(!addressAddStatus)}} name="newAddressStatus" />}
                                    label="Admin"
                                />
                                <TextField
                                    id="addingAddressDescription"
                                    onChange={(e)=>{
                                        setAddressAddDescription(e.target.value)
                                    }
                                    }
                                    label="Description"
                                    variant="outlined"
                                    value={addressAddDescription}
                                    className={classes.field}
                                />
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={onSubmit}
                                    endIcon={<Icon>send</Icon>}
                                >
                                    Add
                                </Button>
                            </>
                            }
                            <TableContainer>
                                <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <React.Fragment>
                                            {userList.map((user) => (
                                                <TableRow className={classes.root}>

                                                    <TableCell>
                                                        <Link
                                                            to={"/admin/address/" + user.address}>{user.address}</Link></TableCell>
                                                    <TableCell>{userStatus(user.status)}</TableCell>
                                                    <TableCell>{user.description}</TableCell>
                                                    <TableCell>
                                                        <IconButton size="small" onClick={()=> {onDelete(user.address)}}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </CardBody>
                </Card>
            </GridItem>
            </>
            }
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Starred Addresses</h4>
                    </CardHeader>
                    <CardBody>
                        <TextField
                            error={errorFav!==""}
                            id="addingAddressFav"
                            onChange={onChangeFav}
                            label="Add Address"
                            helperText={errorFav}
                            variant="outlined"
                            value={addressFavChange}
                        />

                        {errorFav === "" && addressFavChange !== "" &&
                            <>
                        <TextField
                            id="addingAddressDescription"
                            onChange={(e) => {
                                setAddressFavDescription(e.target.value)
                            }
                            }
                            label="Description"
                            variant="outlined"
                            value={addressFavDescription}
                            className={classes.field}
                        />
                            <Button
                            variant="contained"
                            className={classes.button}
                            onClick={onSubmitFav}
                            endIcon={<Icon>send</Icon>}
                            >
                            Add
                            </Button>
                        </>
                        }
                        <TableContainer>
                            <Table stickyHeader style={{backgroundColor: "#f0f0f0"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <React.Fragment>
                                        {starred.map((user) => (
                                            <TableRow className={classes.root}>
                                                <TableCell><Link
                                                    to={"/admin/address/" + user.favorited_address}>{user.favorited_address}</Link></TableCell>
                                                <TableCell>{user.address_description}</TableCell>
                                                <TableCell>
                                                    <IconButton size="small" onClick={()=> {onDeleteFavorite(user.favorited_address)}}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}
