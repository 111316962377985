import {CHANGE_TOKEN_BEGIN,CHANGE_TOKEN_FAILURE,CHANGE_TOKEN_SUCCESS} from "../../types/globalSettings";

export default (state, action) => {
    switch (action.type) {
        case CHANGE_TOKEN_BEGIN:
            return {
                ...state,
                changeTokenPending: true,
            };

        case CHANGE_TOKEN_FAILURE:
            return {
                ...state,
                changeTokenPending: false,
            };

        case CHANGE_TOKEN_SUCCESS:
            return {
                ...state,
                globalToken:action.data,
                changeTokenPending: false,
            };

        default:
            return state;
    }
};

