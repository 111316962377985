import axios from "axios";
import {REACT_APP_API_FLASK_HOST, REACT_APP_API_HOST,REACT_APP_API_HOST_AUTH} from "../config";

const axiosURL = axios.create({
    baseURL: REACT_APP_API_HOST
});

const axiosFlaskURL = axios.create({
    baseURL: REACT_APP_API_FLASK_HOST
})

const axiosAuthURL = axios.create({
    baseURL: REACT_APP_API_HOST_AUTH
})
export {axiosURL,axiosFlaskURL,axiosAuthURL};
