import {CHANGE_TOKEN_BEGIN,CHANGE_TOKEN_FAILURE,CHANGE_TOKEN_SUCCESS} from "../../types/globalSettings";
import {useSelector} from "react-redux";
import {ACS, ACSI} from "../../types/constants";
import {acsToken} from "../../../contracts/tokens/acsToken";
import {acsiToken} from "../../../contracts/tokens/acsiToken";

export function changeToken({globalToken}) {

    return (dispatch) => {
        dispatch({
            type: CHANGE_TOKEN_BEGIN
        });


        if(globalToken===ACS){
            dispatch({
                type: CHANGE_TOKEN_SUCCESS,
                data: {token:ACSI,address:acsiToken.tokenAddress,pair:acsiToken.pair},
            });
        }else{
            dispatch({
                type: CHANGE_TOKEN_SUCCESS,
                data: {token:ACS,address:acsToken.tokenAddress,pair: acsToken.pair}
            });
        }
    }
}
