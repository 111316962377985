import {
    FETCH_TRANSACTION_COUNT_SUCCESS,
    FETCH_TRANSACTION_COUNT_FAILURE,
    FETCH_TRANSACTION_COUNT_BEGIN
} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case FETCH_TRANSACTION_COUNT_BEGIN:
            return {
                ...state,
                fetchTransactionCountDataPending: true,
            };

        case FETCH_TRANSACTION_COUNT_FAILURE:
            return {
                ...state,
                fetchTransactionCountDataPending: false,
            };

        case FETCH_TRANSACTION_COUNT_SUCCESS:
            return {
                ...state,
                [`${action.path}_Count`]:action.data,
                fetchTransactionCountDataPending: false,
            };

        default:
            return state;
    }
};
