import fetchBalances from "./fetchBalances";
import fetchChartData from "./fetchChartData";
import fetchTransactionData from "./fetchTransactionData";
import fetchTransactionCount from "./fetchTransactionCount";
import initialState from "./initialState";
import fetchSignalCount from "./fetchSignalCount";
import fetchSignalData from "./fetchSignalData";
import fetchToken from "./fetchToken";
import fetchStarred from "./fetchStarred";

const reducers = [fetchBalances,fetchChartData,fetchTransactionData,fetchTransactionCount,fetchSignalCount,fetchSignalData,fetchToken,fetchStarred];

export default function reducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        // Handle cross-topic actions here
        default:
            newState = state;
            break;
    }

    return reducers.reduce((s, r) => r(s, action), newState);
}
