const initialState = {
    fetchBalancesPending: false,
    fetchChartDataPending:false,
    fetchTransactionDataPending:false,
    fetchTransactionCountDataPending:false,
    fetchCountDataPending:false,
    fetchSignalCountDataPending: false,
    fetchSignalDataPending: false,
    fetchTokenPending:false,
    Summary_Table_Balance: {},
    Signals_Table_Balance: {},
    Seller_Dashboard_Table_Balance: {},
    Buyer_Dashboard_Table_Balance:{},
    News_Table_Balance:{},
    chartData: [],
    transactionData: [],
    Transaction_Table_Data:[],
    Transaction_Table_Count:[],
    Transaction_Table_Balance:{},
    Transaction_Table_Bought_Data:[],
    Transaction_Table_Bought_Count:[],
    Transaction_Table_Bought_Balance:{},
    Transaction_Table_Sold_Data:[],
    Transaction_Table_Sold_Count:[],
    Transaction_Table_Sold_Balance:{},
    signalData:[],
    countTransactionData: [],
    countSignalData: [],
    tokenData:{},
    starred:[],
    fetchStarredPending:{},
    addressFavList:[]
};

export default initialState;
