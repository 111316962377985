const initialState = {
    
   
    fetchBNBDataPending:false,
    fetchPancakeDataPending:false,
    fetchACSDataPending:false,
    fetchACSIDataPending:false,
    bnbData: [],
    pancakeData: [],
    acsData: [],
    acsiiData: [],
    acsInfo: []
};

export default initialState;
