import {
    FETCH_TRANSACTION_COUNT_BEGIN,
    FETCH_TRANSACTION_COUNT_FAILURE,
    FETCH_TRANSACTION_COUNT_SUCCESS
} from "../../types";
import {GetTransactionCount} from "../../../api/queries";

export function fetchTransactionCount({pair, token, starting,blacklist,table}) {

    return (dispatch) => {
        dispatch({
            type: FETCH_TRANSACTION_COUNT_BEGIN
        });

        return new Promise((resolve, reject) => {
            GetTransactionCount(pair, token, starting,blacklist)
                .then((result)=> {
                    
                    dispatch({
                        type: FETCH_TRANSACTION_COUNT_SUCCESS,
                        data: result,
                        path:table
                    })
                    resolve(result);
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_TRANSACTION_COUNT_FAILURE
                    });
                    console.log(error);
                    return reject(error.message || error);
                })
        })
    }
}
