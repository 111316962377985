import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {changeToken} from "../../actions/globalSettings/changeToken";
export function useChangeToken() {

    const dispatch = useDispatch();

    const {globalToken,globalTokenAddress,globalPair} = useSelector(
        (state) => ({
            globalToken: state.globalSettings.globalToken.token,
            globalTokenAddress: state.globalSettings.globalToken.address,
            globalPair: state.globalSettings.globalToken.pair

        }),
        shallowEqual,
    );

    const boundAction = useCallback(
        (data) => {
            return dispatch(changeToken(data));
        },
        [dispatch],
    );

    return {
        globalToken,
        globalTokenAddress,
        globalPair,
        changeToken: boundAction,
    };
}
